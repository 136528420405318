/*
 * Here you can put global javascript functions
 * They need to be attached to the window object to be globally available
 * Better approach is to make modules and export them (the webpack way) But for now this will do.
 */

window.formatPoint = function(value, decimals)
{
    if (!decimals)
    {
        decimals = 0;
    }
    return value.toFixed(decimals).replace('.', ',');
};

let hideEditLinks = true;
let hideEditLinkTimer = 0;
$('.inline-edit').mouseenter(function () {
    let element = $(this);
    $('.inline-edit-link').hide();
    // normal lang tags
    let editLink = element.addClass('inline-edit-active').parent().find('.inline-edit-link');
    editLink.show();
    // lang tags in href (is moved to same level by browser)
    let editNavLink = element.parent().parent().children('.inline-edit-link');
    editNavLink.show();
    hideEditLinkTimer = setTimeout(function () {
        editLink.hide();
        editNavLink.hide();
        element.removeClass('inline-edit-active');
    }, 2000);
});

$('.inline-edit-link').mouseenter(function () {
    hideEditLinks = false;
    clearTimeout(hideEditLinkTimer);
}).mouseleave(function () {
    hideEditLinks = true;
    $(this).hide();
});

$('#current-contract-info').mouseenter(function () {
    $('#card-switcher').hide();
    $('#current-contract-address').fadeIn();
}).mouseleave(function () {
    $('#current-contract-address').fadeOut('swing', function ()
    {
        $('#card-switcher').show();
    });
});

$('#current-card-info').mouseenter(function () {
    $('#current-card-address').fadeIn();
}).mouseleave(function () {
    $('#current-card-address').fadeOut();
});

/**
 * Datepicker settings
 *
 * https://bootstrap-datepicker.readthedocs.io/en/stable/
 */

$('.datepicker').datepicker({
    todayHighlight: true,
    format: 'dd-mm-yyyy',
    language: 'nl'
});

/**
 * Cleave input field setup. Cleave is a javascript input field filtering tool.
 * See: https://github.com/nosir/cleave.js/
 */

// numerical class only allows numerical values
$('.numerical').toArray().forEach(function(field) {
new Cleave(field, {
    numeral: true,
    numeralDecimalScale: 0,
    numeralThousandsGroupStyle: 'none',
    numeralPositiveOnly: true,
    stripLeadingZeroes: false
    });
});

$('.amount').toArray().forEach(function(field) {
new Cleave(field, {
    numeral: true,
    delimiter: '',
    numeralDecimalMark: ',',
    numeralThousandsGroupStyle: 'none',
    });
});

// phone_number class only allows phone numbers
$('.phone_number').toArray().forEach(function(field) {
new Cleave(field, {
    phone: true,
    phoneRegionCode: 'NL'
    });
});

// iban_nr class only allows IBAN numbers
$('.iban_nr').toArray().forEach(function(field) {
new Cleave(field, {
    blocks: [4, 4, 4, 4, 2],
    uppercase: true
    });
});

// date class only allows (manual entered) dates
$('.date').toArray().forEach(function(field) {
new Cleave(field, {
    date: true,
    delimiter: '-',
    datePattern: ['d', 'm', 'Y']
    });
});


// phone_number class only allows phone numbers
$('.zip_code').toArray().forEach(function(field) {
new Cleave(field, {
    blocks: [6],
    uppercase: true
    });
});

// uppercase input fields
$('.uppercase').toArray().forEach(function(field) {
new Cleave(field, {
    blocks: [30],
    uppercase: true
    });
});

/**
 * End: Cleave input field setup
 */


/**
 * Get a cookie
 *
 * @param cname The cookie name
 * @returns {*} The cookie value
 */
window.getCookie = function(cname)
{
    let result = '';
    let name = cname + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i].trim();
        if (c.indexOf(name) === 0) {
            result = c.substring(name.length, c.length);
            if (result === 'true') {
                result = true;
            }
            else if (result === 'false') {
                result = false;
            }
            return result;
        }
    }
    return result;
};

/**
 * Set a secure global site cookie
 *
 * @param cname The cookie name
 * @param value The cookie value
 * @param expire Expire the cookie
 */
window.setCookie = function(cname, value, expire = false)
{
    let date = '';
    if (value === undefined) {
        value = 'true';
    }
    value = String(value);
    if (expire) {
        date = 'Sat, 1 Jan 2000 12:00:00 GMT';
    } else {
        date = 'Thu, 31 Dec 2100 12:00:00 GMT';
    }
    document.cookie = cname + '=' + value + '; SameSite=None; Secure; expires=' + date + '; path=/';
};

/**
 * Generate a random strong password
 *
 * @param len - the password length
 * @returns {string} - the generated password
 */
window.generatePassword = function(len)
{
    let length = len ? len : 14;
    let string = 'abcdefghijklmnopqrstuvwxyz';
    let numeric = '0123456789';
    let punctuation = '!@#$%^&*()_+~`|}{[]\:;?><,./-=';
    let password = '';
    let character = '';
    while (password.length < length)
    {
        let entity1 = Math.ceil(string.length * Math.random() * Math.random());
        let entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
        let entity3 = Math.ceil(punctuation.length * Math.random() * Math.random());
        let hold = string.charAt(entity1);
        hold = (password.length % 2 === 0) ? hold.toUpperCase() : hold;
        character += hold;
        character += numeric.charAt(entity2);
        character += punctuation.charAt(entity3);
        password = character;
    }
    password = password.split('').sort(function()
    {
        return 0.5-Math.random();
    }).join('');
    return password.substr(0,len);
};

let showPassword = false;
let mobileMenu = false;
let personalMenu = false;
// top should be about the height of the menu (its not easy to get it so we have to define it)
let mobileTop = $('#navMenu').css('top');
let personalTop = $('#personalMenu').css('top');
// quick way to 'detect' mobile devices
let isMobile= window.matchMedia('(max-width: 767px)').matches;

document.addEventListener('DOMContentLoaded', function () {
    $('#password-generator').click(function()
    {
        let password = generatePassword();
        $(this).parent().find('input').attr('type', 'text').val(password);
        // also change and fill the password reminder
        $('#password_confirmation').attr('type', 'text').val(password);
        // remember it
        $('#generated_password').val('1');
    });

    $('#password-show').click(function(event)
    {
        event.stopPropagation();

        showPassword = ! showPassword;
        let input = $(this).parent().find('input');
        if (showPassword)
        {
            $('#password-show-eye').hide();
            $('#password-show-eye-slash').show();
            input.attr('type', 'text').focus();
        }
        else
        {
            $('#password-show-eye-slash').hide();
            $('#password-show-eye').show();
            input.attr('type', 'password').focus();
        }
    });

    // open popup flash modal
    let popup = $('#popup-message');
    let popupHtml = popup.html();
    if (popupHtml && popupHtml.trim())
    {
        $('.popup-modal').modal('show');
        if (popup.hasClass('timeout'))
        {
            setTimeout(function () {
                $('.popup-modal').modal('hide');
            }, 10000);
        }
    }

    // Top mobile menu
    $('.wsmobileheader button#mobile-menu').click(function(event)
    {
        event.stopPropagation();

        showMobileMenu($(this));
    });

    // Personal mobile menu
    $('.wsmobileheader button#personal-menu').click(function(event)
    {
        event.stopPropagation();

        showPersonalMenu($(this));
    });

    // slide down action panel - open
    $('#show-slide-down').click(function(event)
    {
        event.stopPropagation();
        event.preventDefault();

        slideDownPanel(true);
    });

    // slide down action panel - close
    $('#hide-slide-down').click(function(event)
    {
        event.stopPropagation();
        event.preventDefault();

        slideDownPanel(false);
    });

    // when everything is done add the supplier body background
    //$('body').addClass('supplier-body-background');
    // and show the content (slowly)
    $('.content-body').delay(200).queue(function (next) {
        $(this).css('opacity', '1');
        next();
    });

    $('#close-test-emails').click(function() {
        console.log('clicked');
        $('.test-emails').hide();
    });

    // filter rows in (modal) lists
    $('.modal-content .search-list').on('keyup', function() {
        let value = $(this).val().toLowerCase();
        $('.modal-content .searchable-list .row').not('.row-header').filter(function() {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
    });

    // filter rows in dropdown menus
    $('.dropdown-menu .dropdown-search').on('keyup', function() {
        let value = $(this).val().toLowerCase();
        $('.dropdown-menu .dropdown-item').filter(function() {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
        });
    });

    // show add to homescreen info on IOS (no WPA install option yet on Safari)
    $('#add-to-home-screen-ios').click(function()
    {
        $(this).hide();
        $('#pwa-ios-install').show();
    });
});

$(window).bind('beforeunload', function() {
    showLoading();
});

$(window).on('load', function() {
    hideLoading();
});

$(document).on('submit', 'form', function () {
    showLoading();
});

$('.form-menu .dropdown-item').click(function () {
    let element = $(this);
    let text = element.text();

    element.closest('.form-menu').find('a.btn').text(text);
    element.closest('.form-menu').find('input').val(text);

    if (element.data('loop') === 0)
    {
        $('#subject_wrapper').show();
    }
    else
    {
        $('#subject_wrapper').hide();
        $('#subject_wrapper #subject').val('');
    }
});

$('a').click(function () {
    let href = $(this).attr('href');
    let target = $(this).attr('target');
    let classes = $(this).attr('class');
    let parser = document.createElement('a');
    parser.href = href;

    if (href && (href !== ''))
    {
        if (classes && classes.indexOf('dropdown-item') !== 0) {
            // always hide all alert message divs
            $('.alert').hide();
        }

        if ((href.charAt(0) !== '#') && ! target && (parser.hostname === location.hostname))
        {
            showLoading();

            // close all open menu's
            showMobileMenu($('.wsmobileheader button#mobile-menu'), true);
            showPersonalMenu($('.wsmobileheader button#personal-menu'), true);
        }

        if (href.includes('download') || href.includes('pdf'))
        {
            setTimeout(function () {
                hideLoading();
            }, 10000);
        }
    }
});

let top = $('#slide-down').css('top');

/**
 * Show or hide the mobile menu
 *
 * @param button - the button object
 * @param close - true -> close the menu
 */
function showMobileMenu(button, close) {
    // disable the button first
    button.prop('disabled', true);

    // if shown hide it (by moving it to the top)
    if (mobileMenu || close) {
        if (! close) {
            hideLoading();
        }

        $('#overlay').removeClass('overlay-show');
        $('#navMenu').animate({
                top: mobileTop
            }, 1000, function () {
                // animation complete
                $('#navMenu').css('position', 'relative').hide();
                $('.wsmobileheader button#mobile-menu .menu-inactive').show();
                $('.wsmobileheader button#mobile-menu .menu-active').hide();
                // re-enable the button
                button.prop('disabled', false);
            }
        );
    }
    // else show it (slide it in from the top)
    else {
        $('#overlay').addClass('overlay-show');

        // close possible open personal menu
        personalMenu = false;
        $('#personalMenu').animate({
                top: personalTop
            }, 1000, function () {
                // animation complete
                $('#personalMenu').css('position', 'absolute').hide();
                $('.wsmobileheader button#personal-menu .menu-inactive').show();
                $('.wsmobileheader button#personal-menu .menu-active').hide();
            }
        );

        // open the mobile menu
        $('#navMenu').show().animate({
                top: 0
            }, 1000, function () {
                // animation complete
                // $('.content-body').css({'position': 'absolute', 'top': 0});
                $('.wsmobileheader button#mobile-menu .menu-inactive').hide();
                $('.wsmobileheader button#mobile-menu .menu-active').show();
                // re-enable the button
                button.prop('disabled', false);
            }
        );
    }
    mobileMenu = !mobileMenu;
}

/**
 * Show or hide the personal mobile menu
 *
 * @param button - the button object
 * @param close - true -> close the menu
 */
function showPersonalMenu(button, close)
{
    // disable the button first
    button.prop('disabled', true);

    // if shown hide it (by moving it to the top)
    if (personalMenu || close)
    {
        if (! close) {
            hideLoading();
        }

        $('#overlay').removeClass('overlay-show');
        // $('.content-body').css({'position': 'relative', 'top': 'unset'});
        $('#personalMenu').animate({
                top: personalTop
            }, 1000, function() {
                // animation complete
                $('#personalMenu').css('position', 'absolute').hide();
                $('.wsmobileheader button#personal-menu .menu-inactive').show();
                $('.wsmobileheader button#personal-menu .menu-active').hide();
                // re-enable the button
                button.prop('disabled', false);                }
        );
    }
    // else show it (slide it in from the top)
    else
    {
        $('#overlay').addClass('overlay-show');

        // close possible open nav menu
        mobileMenu = false;
        $('#navMenu').animate({
                top: mobileTop
            }, 1000, function() {
                // animation complete
                $('#navMenu').css('position', 'relative').hide();
                $('.wsmobileheader button#mobile-menu .menu-inactive').css('display', 'inline-block');
                $('.wsmobileheader button#mobile-menu .menu-active').css('display', 'none');
            }
        );

        // open the personal menu
        $('#personalMenu').show().animate({
                top: 0
            }, 1000, function() {
                // animation complete
                // $('.content-body').css({'position': 'absolute', 'top': 0});
                $('.wsmobileheader button#personal-menu .menu-inactive').css('display', 'none');
                $('.wsmobileheader button#personal-menu .menu-active').css('display', 'inline-block');
                // re-enable the button
                button.prop('disabled', false);                }
        );
    }
    personalMenu = ! personalMenu;
}

/**
 * Slide down or up a panel
 * For now we only support one slide down panel per page
 *
 * @type {*|jQuery}
 */
function slideDownPanel(show)
{
    if (show)
    {
        // open the slide down panel
        $('#slide-down').show().animate({
                top: 0
            }, 1000, function() {
                // animation complete
            }
        );
    }
    else
    {
        // show the slide down panel
        $('#slide-down').animate({
                top: top
            }, 1000, function() {
                // animation complete
                $('#slide-down').css('position', 'absolute').hide();
            }
        );

    }
}

/**
 * Show the loading indicator
 */
function showLoading()
{
    $('#loading-bar').show();
    setTimeout(function () {
        hideLoading();
    }, 30000);
    $('body').removeClass('supplier-body-background');
    $('.content-body').css('opacity', '0.5');
    $('#overlay').css('z-index', '1002');
}

/**
 * Hide the loading indicator
 */
function hideLoading()
{
    $('#loading-bar').hide();
    $('body').addClass('supplier-body-background');
    $('.content-body').css('opacity', '1');
    $('#overlay').css('z-index', '1001');
}

/**
 * Progressive Web App
 */

let deferredPrompt; // Allows to show the install prompt
const installButton = document.getElementById('add-to-home-screen');

const isInStandaloneMode = () =>
    (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://');

if (isInStandaloneMode()) {
    console.log('webapp is installed');
}

/**
 * Show the install prompt/button on supported platforms
 *
 * @param {string} type - the event to listen too
 * @param {EventListenerOrEventListenerObject} event - the event object
 */
window.addEventListener('beforeinstallprompt', event => {
    console.log('beforeinstallprompt fired');
    // Prevent Chrome 76 and earlier from automatically showing a prompt
    event.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = event;

    if (installButton) {
        // Show the install button
        installButton.hidden = false;
        installButton.addEventListener('click', installApp);
    }
});

/**
 * Install the app
 */
function installApp() {
    // Show the prompt
    deferredPrompt.prompt();

    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then(choiceResult => {
        if (choiceResult.outcome === 'accepted') {
            console.log('PWA install accepted');
            installButton.hidden = true;
        } else {
            console.log('PWA install rejected');
        }
        deferredPrompt = null;
    });
}

/**
 * Run commands after the app is installed
 *
 * @param {string} type - the event to listen too
 * @param {EventListenerOrEventListenerObject} event - the event object
 */
window.addEventListener('appinstalled', event => {
    console.log('appinstalled fired', event);
});

/**
 * Reload the window/content when app is activated/visible again
 *
 * @param {string} type - the event to listen too
 * @param {EventListenerOrEventListenerObject} event - the event object
 */
window.addEventListener('visibilitychange', function () {
    console.log('Visibility changed');
    if ((document.visibilityState === 'visible') && isInStandaloneMode()) {
        console.log('APP resumed');
        showLoading();
        window.location.reload();
    }
});
