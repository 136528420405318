document.addEventListener('DOMContentLoaded', function () {
    /**
     * Debug checkboxes
     */
    $('.checkbox-menu').on('change', 'input[type=\'checkbox\']', function (e) {
        e.stopPropagation();
        $(this).closest('a').toggleClass('active', this.checked);
        doAdminAction($(this).data('action'), this.checked);
    });

    /**
     * Update checkboxes state
     */
    $('input[type=checkbox]').each(function () {
        var name = $(this).data('action');
        var default_value = $(this).data('default');
        var cookie = getCookie(name);

        if ((cookie === '') && default_value)
        {
            cookie = default_value;
            setCookie(name, default_value);
        }
        if (cookie)
        {
            $(this).prop('checked', true);
        }
    });

    /**
     * Update input fields
     */
    $('input[type=text]').each(function () {
        var name = $(this).data('action');
        var cookie = getCookie(name);

        if (cookie)
        {
            $(this).val(cookie);
        }
    });

    /**
     * Debug input fields
     */
    $('.checkbox-menu').on('change', 'input[type=\'text\']', function (e) {
        e.stopPropagation();
        doAdminAction($(this).data('action'), this.value);
    });

    $(document).on('click', '.allow-focus', function (e) {
        e.stopPropagation();
    });

    $(document).on('click', '.hide-admin-options-bar', function (e) {
        e.stopPropagation();
        $('.admin-options-bar').hide();
        $('#admin_button').show();
        setCookie('show_admin_bar', false);
    });

    $(document).on('click', '#admin_button', function (e) {
        e.stopPropagation();
        $('.admin-options-bar').show();
        $('#admin_button').hide();
        setCookie('show_admin_bar', true);
    });

    $(document).on('click', '.inline-edit-bar', function (e) {
        e.stopPropagation();
        setCookie('inline-editing', ! getCookie('inline-editing'));
        location.reload();
    });

    if (getCookie('inline-editing')) {
        $('.inline-edit-icon').addClass('inline-edit-icon-active');
    }

    // On dropdown close (Debug dropdown)
    $('.dropdown-menu-debug').on('hidden.bs.dropdown', function (e) {
        //location.reload();
    });
});

function doAdminAction(action, value) {
    console.log(action);
    setCookie(action, value);
    if (action === 'show_emails') {
        if (value) {
            $('.test-emails').show();
        } else {
            $('.test-emails').hide();
        }
    }

    // not every admin-bar action needs to be sent to the server but for
    // simplicity we just send it
    $.ajax({
        url: '/admin/actions/admin-bar',
        method: 'POST',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        data: {
            action: action,
            value: value
        },
        success: function (data, textStatus, jqXHR) {
            // if (action == 'switch_user') {
            //     window.location.href = '/'
            // } else {
            //     if (data == 'do_reload') {
            //         //do not reload on close dropdown (quickfix)
            //         //location.reload();
            //     }
            // }
        },
        error: function (jqXHR, textStatus, errorThrown) {
            // Handel connection error
        }
    });
}